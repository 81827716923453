<script setup lang="ts">
import { ref, watch, computed } from "vue";
import ExpandIcon from "~/../_common/assets/icons/expand.svg";
import { mdiChevronDown } from "@mdi/js";

// Enum und Props wie zuvor definiert
enum Position {
  Center = "center",
  Right = "right",
  Left = "left",
}

const props = withDefaults(
  defineProps<{
    title?: string;
    titleCollapsed?: string;
    position?: Position | string;
    simpleChevron?: boolean;
    modelValue?: boolean; // v-model Prop
    buttonUnderline?: boolean;
  }>(),
  {
    title: "",
    titleCollapsed: "",
    position: Position.Center,
    simpleChevron: false,
    modelValue: false, // Standardwert für v-model
    buttonUnderline: true,
  },
);

const emit = defineEmits(["update:modelValue"]);

// Verwenden Sie modelValue für den internen Zustand
const isOpen = ref(props.modelValue);

// Aktualisieren Sie isOpen, wenn modelValue sich ändert
watch(
  () => props.modelValue,
  (newVal) => {
    isOpen.value = newVal;
  },
);

// Aktualisieren Sie modelValue, wenn isOpen sich ändert
const toggle = () => {
  isOpen.value = !isOpen.value;
  emit("update:modelValue", isOpen.value);
};

const effectiveTitleCollapsed = computed(() => {
  return props.titleCollapsed || props.title;
});
</script>

<template>
  <div class="expansion-panel">
    <div
        class="expansion-panel-header"
        @click="toggle"
        style="width: fit-content"
        :class="{
        'mx-auto': position === Position.Center,
        'ml-auto': position === Position.Right,
        '': position === Position.Left,
      }"
    >
      <span>
        <MDCSlot name="header" unwrap="p" />

        <hw-button
            :title="isOpen ? effectiveTitleCollapsed : props.title"
            :underline="buttonUnderline"
            :aria-expanded="isOpen"
            class="pa-0"
        >
          <template #icon>
            <div
                :class="{ rotate: isOpen }"
                class="icon--animated d-flex align-center"
            >
              <v-icon v-if="simpleChevron" :icon="[mdiChevronDown]"></v-icon>
              <v-icon v-else :icon="ExpandIcon"></v-icon>
            </div>
          </template>
        </hw-button>
      </span>
    </div>
    <v-expand-transition>
      <div v-show="isOpen" class="expansion-panel-body py-2">
        <MDCSlot name="default" unwrap="p" />
      </div>
    </v-expand-transition>
  </div>
</template>

<style scoped>
.icon--animated {
  transition: rotate 0.2s ease-in-out;
}
.rotate {
  rotate: -180deg;
}
.expansion-panel-header {
  cursor: pointer;
}
</style>
